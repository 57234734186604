<template>
  <div class="plateHistoryResults">
    <div>
      <div class="bootstrap panel">
        <b-table
          id="tableOrder"
          :sort-by="'transactionTimestamp'"
          :sort-desc="true"
          :sticky-header="stickyTableHeight"
          empty-text="No Results"
          :fields="tableFields"
          head-variant="light"
          sort-icon-left
          hover
          responsive="sm"
          :items="plateHistory"
        >
          <template v-slot:cell(transactionTimestamp)="data">
            {{ data.item.transactionTimestamp.substr(0, 10) }}
          </template>
          <template v-slot:cell(registrationExpiresDate)="data">
            {{ data.item.registrationExpiresDate.substr(0, 10) }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      stickyTableHeight: "calc(-100px + 100vh)",
      tableFields: [
        {
          key: "transactionTimestamp",
          label: "Date",
          sortable: true,
          sortDesc: false
        },
        {
          key: "transactionType",
          sortable: true
        },
        {
          key: "titleNo",
          label: "Title",
          sortable: true
        },
        {
          key: "vin",
          sortable: true
        },
        {
          key: "make",
          sortable: true
        },
        {
          key: "model",
          sortable: true
        },
        {
          key: "modelYear",
          label: "Year",
          sortable: true
        },
        {
          key: "registrationExpiresDate",
          label: "Expiration",
          sortable: true
        },
        {
          key: "owner1Name",
          label: "Name",
          sortable: true
        },
        {
          key: "owner1Address",
          label: "Address",
          sortable: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      plateHistory: "plateHistory"
    })
  },
  methods: {
    print() {
      window.print();
    },
    backToSearch() {
      this.$root.$emit("setLoading", true);
      this.$router.push({ name: "PlateHistorySearch" });
      this.$root.$emit("setLoading", false);
    }
  }
};
</script>
<style scoped>
.plateHistoryResults {
  padding: 30px;
  overflow-y: auto;
  max-height: 100%;
}
</style>
